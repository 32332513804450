.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85vh;
    background-color: aliceblue;
    background-image: url('../../images/garage.jpg');
    background-size: cover; 
    background-position: 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__content {
        z-index: 1;
        h1 {
            font-size: 70px;
            color: white;
            font-weight: 500;
            @media screen and (max-width: 1024px) {
                font-size: calc(50px + 20 * ((100vw - 390px) / (1024 - 390)));
                margin: 0 0 1rem 0;
            }
        }
        button {
            background-color: #FF1F1F;
            color: white;
            border: none;
            border-radius: 500px;
            padding: 10px 25px;
            font-size: 16px;
            cursor: pointer;
        }
    }
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the last value (0.5) for darkness level */
  }