.container {
    background-color: black;
    &__content {
        margin: 0 auto;
        padding: 3rem 0;
        max-width: 1100px;
        @media screen and (max-width: 1160px) {
            margin: 0 1rem;
        }
        h1 {
            font-size: 30px;
            text-align: center;
            color: white;
            font-weight: 500;
            @media screen and (max-width: 1024px) {
                font-size: calc(26px + 4 * ((100vw - 390px) / (1024 - 390)));
                margin: 0;
            }
        }
        button {
            background-color: #FF1F1F;
            color: white;
            border: none;
            border-radius: 500px;
            padding: 10px 25px;
            font-size: 16px;
            margin-top: 2rem;
            cursor: pointer;
        }
    }
}