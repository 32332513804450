.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 3rem auto;
    @media screen and (max-width: 1160px) {
        margin: 2rem 1rem;
    }
    a {
        text-decoration: none;
    }
    &__content {
        h1 {
            font-size: 70px;
            font-weight: 500;
            margin: 0;
            @media screen and (max-width: 1024px) {              
                font-size: calc(50px + 20 * ((100vw - 390px) / (1024 - 390)));
                margin: 0;               
            }
        }
        p {
            color: #737375;
            font-size: 16px;
            cursor: pointer;
            font-weight: 400;
        }
        p:hover {
            text-decoration: underline;
        }
    }
}
