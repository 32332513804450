.container {
    max-width: 1100px;
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1160px) {
        margin: 2rem 1rem;
    }
    &__heading {
        h1 {
            font-size: 34px;
            font-weight: 500;
            @media screen and (max-width: 1024px) {
                font-size: calc(30px + 4 * ((100vw - 390px) / (1024 - 390)));
                margin: 0;
            }
        }
      
    }
    &__text {
        p {
            font-size: 16px;
            color: #737375;
            text-align: left;
            margin: 1.5rem auto;
        }
        
        span {
            font-weight: bold;
        }
    }
}