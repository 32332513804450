.container {
    max-width: 1100px;
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1160px) {
       margin: 2rem 1rem;
    }
    &__heading {
        h1 {
            font-size: 34px;
            font-weight: 500;
            @media screen and (max-width: 1024px) {
                font-size: calc(30px + 4 * ((100vw - 390px) / (1024 - 390)));
                margin: 0;
            }
        }
        h2 {
            margin-top: 2rem;
            font-size: 16px;
            font-weight: 400;
            color: #737375;
            @media screen and (max-width: 1024px) {
                margin: 1rem auto;
            }
        }
    }
    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 1.5rem;
        @media screen and (max-width: 403px) {
           justify-content: center;
           gap: 1rem;
        }
        &__item {
            width: 16%;
            height: fit-content;
            aspect-ratio: 1/1;
            min-width: 170px;
            &__box {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 25px;
                padding: 30px;
                img {
                    width: 100%;
                    aspect-ratio: 1/1;
                    @media screen and (max-width: 1024px) {
                        max-width: 90px;
                    }
                }
            }
            p {
                font-size: 16px;
                width: 80%;
                margin: 1rem auto;
            }
        }
    }
}