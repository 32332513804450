.container {
    max-width: 1100px;
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1160px) {
      margin: 2rem 1rem;
    }
  
    &__images {
      margin-top: 3rem;
      display: flex;
      align-items: center;
      gap: 1.5rem;
      @media screen and (max-width: 1160px) {
        gap: 1rem;
        margin-top: 2rem;
      }
      @media screen and (max-width: 870px) {
       gap: .5rem;
      }
      @media screen and (max-width: 500px) {
        flex-direction: column;
        gap: 1rem;
      }
      img {
        border-radius: 10px;
        width: 49.09%;
        max-width: 540px;
        max-height: 347px;
        aspect-ratio: 3 / 2;
        @media screen and (max-width: 500px) {
          width: 100%;
        }
      }
    }
  
    p {
      font-size: 16px;
      text-align: left;
    }
  }
  