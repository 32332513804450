.container {
    max-width: 1100px;
    margin: 3rem auto;
    @media screen and (max-width: 1160px) {
        margin: 2rem 1rem;
    }
    &__heading {
        h1 {
            font-size: 34px;
            font-weight: 500;
            @media screen and (max-width: 1024px) {
                font-size: calc(30px + 4 * ((100vw - 390px) / (1024 - 390)));
                margin: 0;
            }
        }
        h2 {
            margin-top: 2rem;
            font-size: 16px;
            font-weight: 400;
            @media screen and (max-width: 1024px) {
                margin: 1rem auto;
            }
        }
    }
    &__text {
        p {
            margin: 1.5rem auto;
            text-align: left;
            color: #737375;
        }
    }
}

.map {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin-top: 2.5rem;
}

.map iframe {
    position: absolute;
    left: 0;
    top: 0;
    border: 0;
    height: 100%;
    width: 100%;

}