.container {
    max-width: 1100px;
    margin: 3rem auto;
    margin-bottom: 6rem;
    &__alert {
        display: none !important;
        padding: 0.35rem 4rem !important;
        z-index: 555;
        position: fixed !important;
        top: 2.5rem;
        width: fit-content;
        left: 50%;
        transform: translateX(-50%);
        transition: width 2s, height 4s;
        -webkit-transition: opacity 2s ease-in-out;
        -moz-transition: opacity 2s ease-in-out;
        -o-transition: opacity 2s ease-in-out;
        transition: opacity 2s ease-in-out;
    
        &--active {
          display: flex !important;
        }
    }
    @media screen and (max-width: 1160px) {
        margin: 2rem 1rem;
    }
    h1 {
        font-size: 34px;
        text-align: left;
        font-weight: 500;
        @media screen and (max-width: 1024px) {
            font-size: calc(30px + 4 * ((100vw - 390px) / (1024 - 390)));
            margin: 0 0 1rem 0;
        }
    }
    &__form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        &__user {
            display: flex;
            gap: 1.5rem;
            justify-content: center;
            @media screen and (max-width: 1160px) {
                gap: 1rem;
            }
            @media screen and (max-width: 870px) {
                gap: .5rem;
            }
            @media screen and (max-width: 500px) {
                flex-direction: column;
                gap: 1rem;
            }
            &__item {
                display: flex;
                width: 49.09%;
                max-width: 540px;
                flex-direction: column;
                align-items: flex-start;
                gap: .5rem;
                @media screen and (max-width: 500px) {
                  width: 100%;
                }
                label {
                    font-size: 16px;
                }
                input {
                    max-width: 540px;
                    width: calc(100% - 10px);
                    padding: 10px 0 10px 10px;
                    color: #d3d3d3;
                    font-size: 16px;
                    border-radius: 10px;
                    border: 1px solid #d3d3d3;
                }

                input:focus {
                    outline: none;
                    border-color: black;
                    color: black;
                }
            }
        }
        &__message {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: .5rem;
            textarea {
                padding: 10px 0 10px 10px;
                border-radius: 10px;
                width: 100%;
                width: calc(100% - 10px);
                border: 1px solid #d3d3d3;
                color: #d3d3d3;
                font-size: 16px;
                resize: none;
            }
            textarea:focus {
                outline: none;
                border-color: black;
                color: black;
            }
        }
        button {
            background-color: black;
            color: white;
            border: none;
            border-radius: 500px;
            padding: 10px 25px;
            font-size: 16px;
            width: 178px;
            cursor: pointer;
        }
    }

    h2 {
        font-size: 34px;
        font-weight: 500;
    }
}